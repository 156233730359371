import endpoints from "@/services/config/service.endpoints";
import { emsCloud as http } from "@/services/config/http.config";

import state from "@/state";
import type { Session } from "@/types/auth/auth.model";
import type { Credentials } from "@/types/auth/auth.api";

const { login: baseURL } = endpoints;

export default {
  async loginEMS(credentials: Credentials): Promise<Session> {
    const { data } = await http.post<string>(baseURL, credentials);
    return {
      token: data,
      expires: Date.now() + 1000 * 60 * 60 * 24 * 30,
    };
  },

  async loginVehicleSharing(credentials: Credentials): Promise<Session> {
    const { data } = await http.post<Session>(baseURL, credentials);
    return data;
  },

  logout(): boolean {
    state.account.resetAccountState();
    state.global.resetSessionToken();
    return true;
  },
};
