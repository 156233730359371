import endpoints from "@/services/config/service.endpoints";
import getBaseURL from "@/services/utils/base-url.utils";
import type { Account } from "@/types/account/account.model";
import { emsCloud as http } from "@/services/config/http.config";

const { me } = endpoints;
const baseURL = getBaseURL(me);

export default {
  async getAccount(): Promise<Account> {
    const { data } = await http.get<Account>(baseURL);
    return data;
  },
};
