import getBaseURL from "@/services/utils/base-url.utils";
import endpoints from "@/services/config/service.endpoints";
import { emsCloud as http } from "@/services/config/http.config";

import type {
  CommandPayload,
  CommandResponse,
} from "@/types/command/command.api";

const { ems } = endpoints;
const baseURL = getBaseURL(ems.commands);

export default {
  async getCommand(payload: CommandPayload): Promise<CommandResponse> {
    const { sessionId } = payload;
    const { data } = await http.get<CommandResponse>(`${baseURL}/${sessionId}`);
    return data;
  },
};
