import type { App } from "vue";
import toolkit from "@wallbox/toolkit-ui";
import "@wallbox/toolkit-ui/dist/lib/toolkit-ui.css";

export default function initToolkit(app: App) {
  app.use(toolkit, {
    notify: {
      timeout: 4000,
      transition: "slide",
    },
  });
}
