import getBaseURL from "@/services/utils/base-url.utils";
import endpoints from "@/services/config/service.endpoints";
import { emsCloud as http } from "@/services/config/http.config";

import type { Settings } from "@/types/settings/settings.model";
import type {
  GetSettingsPayload,
  SaveSettingsPayload,
  SettingActionResponse,
} from "@/types/settings/settings.api";

const { ems } = endpoints;
const baseURL = getBaseURL(ems.main);

export default {
  async getSettings(payload: GetSettingsPayload): Promise<Settings> {
    const { localControllerId } = payload;
    const { data } = await http.get<Settings>(
      `${baseURL}/${localControllerId}/${ems.settings}`
    );
    return data;
  },
  async saveSettings(
    payload: SaveSettingsPayload
  ): Promise<SettingActionResponse> {
    const { localControllerId } = payload;
    const { data } = await http.patch<SettingActionResponse>(
      `${baseURL}/${localControllerId}/${ems.settings}`,
      payload
    );
    return data;
  },
};
