import services from "@/services";
import { setFeatureFlagsByUserLogged } from "@/engine/featureFlags/featureFlags.state";
import type { FeatureFlag } from "./featureFlags.types";

export default async function initFeatureFlags() {
  try {
    const data: FeatureFlag[] = await services.flags.getFeatureFlags();
    setFeatureFlagsByUserLogged(data);
  } catch (error) {
    console.error("Error getting feature flags", error);
  }
}
