export type UserRoleName = "admin" | "user";

export enum UserStateItem {
  user = "user",
}

export interface UserState {
  [UserStateItem.user]: User | null;
}

export interface UserRole {
  id: string;
  name: UserRoleName;
}

export interface UserOrganization {
  id: string;
  name: string;
}

export interface User {
  id: string;
  email: string;
  name: string;
  organization: UserOrganization;
  role: UserRole;
}

export interface Users {
  counter: number;
  users: User[];
}
