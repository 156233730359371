enum RoutesNames {
  home = "/",
  login = "login",
  dashboard = "dashboard",
  fleet = "fleet",
  vehicle = "vehicle",
  drivers = "drivers",
  bookings = "bookings",
  booking = "booking",
  devices = "devices",
  device = "device",
  list = "list",
  tree = "tree",
  settings = "settings",
  notFound = "not-found",
}

export default RoutesNames;
