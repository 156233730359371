import type { RouteRecordRaw } from "vue-router";
import RoutesNames from "@/constants/routes.consts";

const fleetRoutes: RouteRecordRaw[] = [
  {
    path: `/${RoutesNames.fleet}`,
    name: RoutesNames.fleet,
    component: () => import("@/pages/Fleet/FleetPage.vue"),
  },
  {
    path: `/${RoutesNames.fleet}/:id`,
    name: RoutesNames.vehicle,
    props: true,
    component: () => import("@/pages/Fleet/VehicleDetailsPage.vue"),
  },
];

export default fleetRoutes;
