import type { Env } from "./types.env";
import getCurrentEnv from "./current.env";

const currentEnv: Env = getCurrentEnv();

const webEnv: Env = {
  name: currentEnv.name,
  client: currentEnv.client,
  api: {
    version: currentEnv.api.version,
    ems: currentEnv.api.ems,
    vehicleSharing: currentEnv.api.vehicleSharing,
  },
  grafana: {
    baseUrl: currentEnv.grafana.baseUrl,
    projectUrl: currentEnv.grafana.projectUrl,
  },
  datadog: {
    env: currentEnv.datadog.env,
    active: currentEnv.datadog.active,
    clientToken: currentEnv.datadog.clientToken,
    applicationId: currentEnv.datadog.applicationId,
  },
  apiMock: {
    baseUrl: currentEnv.apiMock?.baseUrl,
  },
  featureFlags: {
    baseUrl: currentEnv.featureFlags.baseUrl,
    active: currentEnv.featureFlags.active,
  },
};

export default webEnv;
