import ENV from "@/engine/env/web.env";
import isApiMockActive from "@/utils/isApiMockActive";

export enum Service {
  EMS_CLOUD = "ems-cloud",
  FEATURE_FLAGS = "feature-flags",
  VEHICLE_SHARING = "vehicle-sharing",
}

export const getBaseUrl = (service: Service) => {
  if (isApiMockActive) {
    return ENV.apiMock?.baseUrl;
  }

  if (service === Service.EMS_CLOUD) {
    return ENV.api.ems;
  }

  if (service === Service.FEATURE_FLAGS) {
    return ENV.featureFlags.baseUrl;
  }

  return ENV.api.vehicleSharing;
};
