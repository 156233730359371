import ENV from "@/engine/env/web.env";
import { datadogRum } from "@datadog/browser-rum";

export default function initClientTrackers() {
  datadogRum.init({
    env: ENV.datadog.env,
    applicationId: ENV.datadog.applicationId,
    clientToken: ENV.datadog.clientToken,
    site: "datadoghq.com",
    service: "sirius",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });

  datadogRum.startSessionReplayRecording();
}
