import { reactive, computed } from "vue";
import { objects } from "@wallbox/toolkit-ui";

import constants from "@/constants";
import { VehiclesStateItem } from "@/types/vehicle/vehicle.state";
import type {
  VehiclesState,
  VehiclesFilters,
} from "@/types/vehicle/vehicle.state";

const filters: VehiclesFilters = {
  pagination: {
    offset: 0,
    limit: constants.global.PAGINATION.LIMIT,
  },
};

const initialState: VehiclesState = {
  filters,
};

const state = reactive(initialState);

const getters = {
  getFilters: computed((): VehiclesFilters => state.filters),
};

const setters = {
  setFilters(filters: VehiclesFilters): void {
    objects.setProperty(state, VehiclesStateItem.filters, filters);
  },
};

export default reactive({
  ...getters,
  ...setters,
});
