import type { DeviceFilters } from "@/types/device/device.state";
import { getParams, getParamsPagination } from "@/services/utils/commons.utils";

function getDevicesParams(filters: DeviceFilters): string {
  const type = getParams(filters.type, "type");
  const protocol = getParams(filters.protocol, "protocol");
  const status = getParams(filters.status, "status");
  const pagination = getParamsPagination(filters.pagination);

  return `${pagination}${type}${protocol}${status}`;
}

export default {
  getDevicesParams,
};
