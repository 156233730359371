import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

import state from "@/state";
import RoutesNames from "@/constants/routes.consts";

export default function hasAuth(
  to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const token = state.global.getSessionToken();
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  const shouldRedirectToLogin = requiresAuth && !token;
  const shouldRedirectToMain = token && to.name === RoutesNames.login;

  if (shouldRedirectToLogin) {
    next({ name: RoutesNames.login, query: to.query });
    return;
  } else if (shouldRedirectToMain) {
    next({ name: RoutesNames.devices, query: to.query });
    return;
  }

  next();
}
