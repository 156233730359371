import endpoints from "@/services/config/service.endpoints";
import { featureFlags as http } from "@/services/config/http.config";
import type { FeatureFlag } from "@/engine/featureFlags/featureFlags.types";

const { featureFlag } = endpoints;

export default {
  async getFeatureFlags(): Promise<FeatureFlag[]> {
    const { data } = await http.get<FeatureFlag[]>(`/${featureFlag}`);
    return data;
  },
};
