import type { JwtPayload } from "jwt-decode";
import type { WbBreadcrumb } from "@/types/wallbox.toolkit-ui";
import type { LocalControllersStore } from "@/types/local-controller/local-controller.model";

export enum GlobalStateItem {
  sessionToken = "sessionToken",
  sessionPayload = "sessionPayload",
  localControllers = "localControllers",
  sidebarExpanded = "sidebarExpanded",
  breadcrumb = "breadcrumb",
}

export interface SessionPayload extends JwtPayload {
  role: string;
  id: string;
  email: string;
  account_id: string;
  user_name: string;
  organization_id: string;
  local_controllers: string[];
}

export interface GlobalState {
  [GlobalStateItem.sessionToken]: string | null;
  [GlobalStateItem.sessionPayload]: SessionPayload | null;
  [GlobalStateItem.localControllers]: LocalControllersStore;
  [GlobalStateItem.sidebarExpanded]: boolean;
  [GlobalStateItem.breadcrumb]: WbBreadcrumb[] | null;
}
