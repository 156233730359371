<template>
  <component :is="icon" />
</template>

<script setup lang="ts">
import { defineAsyncComponent } from "vue";

interface Props {
  name: string;
}

const props = defineProps<Props>();

const icon = defineAsyncComponent(
  () => import(`../../assets/icons/${props.name}.svg`)
);
</script>
