import { reactive, computed } from "vue";
import { objects } from "@wallbox/toolkit-ui";

import constants from "@/constants";
import { DeviceStateItem } from "@/types/device/device.state";

import { DeviceStatus } from "@/types/device/device.enum";
import type { DeviceListItem } from "@/types/device/device.model";
import type { DeviceState, DeviceFilters } from "@/types/device/device.state";

const initialStateDevices = [] as DeviceListItem[];

const initialStateFilters: DeviceFilters = {
  type: [],
  status: [],
  protocol: [],
  pagination: {
    offset: 0,
    limit: constants.global.PAGINATION.LIMIT,
  },
};

const initialState: DeviceState = {
  filters: initialStateFilters,
  devices: initialStateDevices,
};

const state = reactive(initialState);

const getters = {
  getFilters: computed((): DeviceFilters => state.filters),
  getDevices: computed((): DeviceListItem[] => state.devices),
};

const setters = {
  setFilters(filters: DeviceFilters): void {
    objects.setProperty(state, DeviceStateItem.filters, filters);
  },
  resetFilters(): void {
    objects.setProperty(state, DeviceStateItem.filters, initialStateFilters);
  },
  setDevices(devices: DeviceListItem[]): void {
    objects.setProperty(state, DeviceStateItem.devices, devices);
  },
  resetDevices(): void {
    objects.setProperty(state, DeviceStateItem.devices, initialStateDevices);
  },
  updateDeviceStatus(device: DeviceListItem, status: DeviceStatus) {
    return {
      ...device,
      ui: {
        ...device.ui,
        status,
      },
    };
  },
  updateDeviceStatusToRestarting(id: string): void {
    const devicesUpdated = state.devices.map((device) => {
      if (device.id === id) {
        return setters.updateDeviceStatus(device, DeviceStatus.Restarting);
      }

      return device;
    });

    setters.setDevices(devicesUpdated);
  },
};

export default reactive({
  ...getters,
  ...setters,
});
