<template>
  <div class="auth-layout" :class="{ 'with-title': slots.title }">
    <div class="auth-wrapper">
      <div class="auth-layout__logo">
        <sirius-icon name="sirius-by-wallbox" />
      </div>
      <div v-if="slots.title" class="auth-layout__title">
        <slot name="title" />
      </div>
      <div class="auth-layout__content">
        <slot />
      </div>
      <template v-if="slots.footer">
        <slot name="footer" />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSlots } from "vue";
import SiriusIcon from "@/components/Global/SiriusIcon.vue";

const slots = useSlots();
</script>

<style lang="postcss" scoped>
.auth-layout {
  height: 100vh;
  display: grid;
  justify-content: center;
  align-content: center;

  & .auth-wrapper {
    width: 30rem;

    & .auth-layout__logo {
      text-align: center;
    }

    & .auth-layout__title {
      align-self: flex-start;
      justify-self: center;
      text-align: center;
      padding: 5rem 0 4rem;
    }

    & .auth-layout__content {
      align-self: flex-start;
    }
  }
}
</style>
