import type { Filter } from "@/types/filter.api";
import type { Pagination } from "@/types/pagination.api";
import type { BookingStatusState } from "@/types/booking/booking.model";

export interface BookingFilters extends Filter {
  drivers: string[];
  vehicles: string[];
  dates: [Date, Date];
  pagination: Pagination;
  status: BookingStatusState[];
}

export enum BookingStateItem {
  filters = "filters",
}

export interface BookingState {
  [BookingStateItem.filters]: BookingFilters;
}
