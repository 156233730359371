import axios, { type AxiosInstance } from "axios";
import { getBaseUrl, Service } from "@/services/config/service.config";
import {
  handleError,
  handleRequest,
  handleResponse,
} from "@/services/config/http.handlers";

const emsCloud: AxiosInstance = axios.create({
  baseURL: getBaseUrl(Service.EMS_CLOUD),
});

const vehicleSharing: AxiosInstance = axios.create({
  baseURL: getBaseUrl(Service.VEHICLE_SHARING),
});

const featureFlags: AxiosInstance = axios.create({
  baseURL: getBaseUrl(Service.FEATURE_FLAGS),
});

vehicleSharing.interceptors.request.use(handleRequest, handleError);
vehicleSharing.interceptors.response.use(handleResponse, handleError);

emsCloud.interceptors.request.use(handleRequest, handleError);
emsCloud.interceptors.response.use(handleResponse, handleError);

export { emsCloud, vehicleSharing, featureFlags };
