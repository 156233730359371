import type { SetupWorkerApi } from "msw";
import isApiMockActive from "@/utils/isApiMockActive";

interface MockServiceWorker {
  worker: SetupWorkerApi;
}

export default async function initApiMock() {
  if (isApiMockActive) {
    return import("@/mocks/browser").then((msw: MockServiceWorker) =>
      msw.worker.start({
        onUnhandledRequest: "bypass",
      })
    );
  }

  return Promise.resolve();
}
