import { reactive } from "vue";
import state from "@/state";
import isApiMockActive from "@/utils/isApiMockActive";
import type {
  Strategy,
  FeatureFlag,
  FeatureFlagsState,
} from "@/engine/featureFlags/featureFlags.types";

const dynamicDomain = /(pages.dev)$/;
const { hostname } = document.location;

const initialFeatures = (): FeatureFlagsState => ({
  flags: [],
});

const features = reactive(initialFeatures());

const hasFeatureFlagByEnvironment = (strategy: Strategy): boolean => {
  if (strategy.scopes.some((item) => item.environment_scope === "*"))
    return true;

  return (strategy.scopes ?? []).some(({ environment_scope }) =>
    hostname.includes(environment_scope)
  );
};

const hasFeatureFlagByUser = (strategy: Strategy): boolean => {
  const users = (strategy.parameters.userIds ?? "").split(",").filter(Boolean);
  if (!users.length) return true;

  const userLogged = state.user.getUserData;

  return !!(
    userLogged &&
    (users.includes(userLogged.id) || users.includes(userLogged.email))
  );
};

const isFeatureFlagsDisabled = (): boolean => {
  return isApiMockActive || dynamicDomain.test(hostname);
};

export const hasFeatureFlagActive = (flagName: string): boolean => {
  const feature: FeatureFlag | undefined = features.flags.find(
    (flag) => flag.name === flagName
  );

  if (isFeatureFlagsDisabled()) return true;

  if (feature && !feature.active) return false;

  const strategies = feature ? feature.strategies : [];

  const hasStrategy = strategies.reduce(
    (previousValue: boolean, currentValue: Strategy) => {
      return (
        previousValue ||
        (hasFeatureFlagByEnvironment(currentValue) &&
          hasFeatureFlagByUser(currentValue))
      );
    },
    false
  );

  return hasStrategy;
};

export function setFeatureFlagsByUserLogged(data: FeatureFlag[]): void {
  features.flags = Array.isArray(data) ? data : [];
}
