import { reactive } from "vue";
import jwtDecode from "jwt-decode";
import { objects } from "@wallbox/toolkit-ui";

import { GlobalStateItem } from "@/types/global.model";
import type { GlobalState, SessionPayload } from "@/types/global.model";
import type { WbBreadcrumb, WbSelectOption } from "@/types/wallbox.toolkit-ui";
import type { LocalControllersStore } from "@/types/local-controller/local-controller.model";

const WB_SIRIUS = "wb-sirius";

const localStorageData = localStorage.getItem(WB_SIRIUS);
const storedState = localStorageData ? JSON.parse(localStorageData) : null;

const initialState: GlobalState = {
  breadcrumb: null,
  sessionToken: null,
  sessionPayload: null,
  sidebarExpanded: true,
  localControllers: { activeLocation: { value: "", label: "" }, locations: [] },
};

const state = reactive<GlobalState>(storedState || initialState);

const updateStoredState = (data: GlobalState) =>
  localStorage.setItem(WB_SIRIUS, JSON.stringify(data));

const getters = {
  getSessionToken(): string | null {
    return state.sessionToken;
  },
  getSessionPayload(): SessionPayload | null {
    return state.sessionPayload;
  },
  getActiveLocalController(): WbSelectOption {
    return state.localControllers.activeLocation;
  },
  getActiveLocalControllerId(): string {
    return (
      state.localControllers?.activeLocation?.value ||
      state.sessionPayload?.local_controllers[0] ||
      ""
    );
  },
  getLocalControllerLocations(): WbSelectOption[] {
    return state.localControllers.locations;
  },
  isSidebarCollapsed(): boolean {
    return !state.sidebarExpanded;
  },
  getBreadcrumb(): WbBreadcrumb[] | null {
    return state.breadcrumb;
  },
};

const setters = {
  setSessionToken(value: string): void {
    objects.setProperty(state, GlobalStateItem.sessionToken, value);
    updateStoredState(state);
  },
  setSessionPayload(value: string): void {
    const sessionPayload = jwtDecode<SessionPayload>(value);

    objects.setProperty(state, GlobalStateItem.sessionPayload, sessionPayload);
    updateStoredState(state);
  },
  setLocalControllers(localControllers: LocalControllersStore): void {
    objects.setProperty(
      state,
      GlobalStateItem.localControllers,
      localControllers
    );

    updateStoredState(state);
  },
  setActiveLocalController(id: string): void {
    const item = state.localControllers.locations.find(
      (location) => location.value === id
    );

    const activeLocation = item || state.localControllers.activeLocation;

    const localControllers = {
      ...state.localControllers,
      activeLocation,
    };

    objects.setProperty(
      state,
      GlobalStateItem.localControllers,
      localControllers
    );

    updateStoredState(state);
  },
  resetSessionToken(): void {
    objects.setProperty(state, GlobalStateItem.sessionToken, null);
    objects.setProperty(state, GlobalStateItem.sessionPayload, null);
    objects.setProperty(state, GlobalStateItem.localControllers, null);
    updateStoredState(state);
  },
  setSidebarExpanded(value: boolean): void {
    objects.setProperty(state, GlobalStateItem.sidebarExpanded, value);
    updateStoredState(state);
  },
  setBreadcrumb(value: WbBreadcrumb[]): void {
    objects.setProperty(state, GlobalStateItem.breadcrumb, value);
    updateStoredState(state);
  },
};

if (!storedState) {
  updateStoredState(state);
}

export default reactive({
  ...getters,
  ...setters,
});
