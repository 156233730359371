import getBaseURL from "@/services/utils/base-url.utils";
import endpoints from "@/services/config/service.endpoints";
import { emsCloud as http } from "@/services/config/http.config";

import type { DevicesTreePayload } from "@/types/device/device.api";
import type {
  LocalControllers,
  LocalControllerTree,
} from "@/types/local-controller/local-controller.model";

const { ems } = endpoints;
const baseURL = getBaseURL(ems.main);

export default {
  async getLocalControllers(): Promise<LocalControllers> {
    const { data } = await http.get<LocalControllers>(baseURL);
    return data;
  },

  async getLocalControllerTree(
    payload: DevicesTreePayload
  ): Promise<LocalControllerTree> {
    const { localControllerId } = payload;

    const { data } = await http.get<LocalControllerTree>(
      `${baseURL}/${localControllerId}/${ems.tree}`
    );

    return data;
  },
};
