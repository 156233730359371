export enum AccountRole {
  BASIC = "user",
  ADMIN = "admin",
  SUPER_ADMIN = "super_admin",
}

export enum AccountStateItem {
  account = "account",
}

export interface AccountState {
  [AccountStateItem.account]: Account | null;
}

export interface Account {
  id: string;
  name: string;
  email: string;
  account_id: string;
  role: AccountRole;
  created_at: string;
  updated_at: string;
}
