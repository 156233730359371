export default {
  getTime(dateString: string): string {
    const date = new Date(dateString);
    const hours = date
      .getHours()
      .toLocaleString("en-US", { minimumIntegerDigits: 2 });
    const minutes = date
      .getMinutes()
      .toLocaleString("en-US", { minimumIntegerDigits: 2 });
    return `${hours}:${minutes} h`;
  },
  getDate(dateString: string): string {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
    });
    const day = date
      .getDate()
      .toLocaleString("en-US", { minimumIntegerDigits: 2 });
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  },
  addDays(date: Date, days: number): Date {
    return new Date(date.setDate(date.getDate() + days));
  },
  subDays(date: Date, days: number): Date {
    return new Date(date.setDate(date.getDate() - days));
  },
};
