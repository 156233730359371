import type { WbLabel } from "@/types/wallbox.toolkit-ui";
import type { BookingStatusState } from "@/types/booking/booking.model";

enum TYPES {
  BUSINESS = "business",
  PERSONAL = "personal",
}

enum TABS {
  UPCOMING = "upcoming",
  PAST = "past",
}

const STATUS: Record<BookingStatusState, WbLabel<BookingStatusState>> = {
  upcoming: {
    value: "upcoming",
    translation: "bookings.status.upcoming",
    color: "warning",
  },
  ready: {
    value: "ready",
    translation: "bookings.status.ready",
    color: "info",
  },
  ongoing: {
    value: "ongoing",
    translation: "bookings.status.ongoing",
    color: "info",
  },
  cancelled: {
    value: "cancelled",
    translation: "bookings.status.cancelled",
    color: "grey",
  },
  error: {
    value: "error",
    translation: "bookings.status.error",
    color: "danger",
  },
  done: {
    value: "done",
    translation: "bookings.status.done",
    color: "info",
  },
  aborted: {
    value: "aborted",
    translation: "bookings.status.aborted",
    color: "warning",
  },
} as const;

enum STEP {
  "INFO" = 1,
  "DATES" = 2,
  "VEHICLES" = 3,
  "DRIVER" = 4,
}

export default {
  TYPES,
  TABS,
  STATUS,
  STEP,
};
