import { createApp } from "vue";
import initApp from "@/engine";
import "@/styles/main.css";

(async () => {
  const app = createApp({ template: "<router-view />" });
  const { i18n, router } = await initApp(app);

  app.use(router).use(i18n).mount("#app");
})();
