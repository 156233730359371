import type { RouteRecordRaw } from "vue-router";
import RoutesNames from "@/constants/routes.consts";

const devicesRoutes: RouteRecordRaw[] = [
  {
    path: `/${RoutesNames.devices}`,
    name: RoutesNames.devices,
    redirect: `/${RoutesNames.devices}/${RoutesNames.list}`,
    component: () => import("@/layout/LayoutDevices.vue"),
    children: [
      {
        path: `/${RoutesNames.devices}/${RoutesNames.list}`,
        name: `${RoutesNames.devices}-${RoutesNames.list}`,
        component: () => import("@/pages/Devices/DevicesListPage.vue"),
      },
      {
        path: `/${RoutesNames.devices}/${RoutesNames.tree}`,
        name: `${RoutesNames.devices}-${RoutesNames.tree}`,
        component: () => import("@/pages/Devices/DevicesTreePage.vue"),
      },
    ],
  },
  {
    path: `/${RoutesNames.devices}/:id`,
    name: `${RoutesNames.device}`,
    props: true,
    component: () => import("@/pages/Devices/DeviceDetailsPage.vue"),
  },
];

export default devicesRoutes;
