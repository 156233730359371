import { getParamsPagination } from "@/services/utils/commons.utils";
import type { VehiclesFilters } from "@/types/vehicle/vehicle.state";

function getVehiclesParams(filters: VehiclesFilters): string {
  const pagination = getParamsPagination(filters.pagination);
  return pagination;
}

export default {
  getVehiclesParams,
};
