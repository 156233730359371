import type {
  AxiosError,
  InternalAxiosRequestConfig,
  AxiosResponse,
} from "axios";

import state from "@/state";
import RoutesNames from "@/constants/routes.consts";
import type { ApiError } from "@/types/error.model";

export function handleRequest(config: InternalAxiosRequestConfig) {
  const token = state.global.getSessionToken();

  if (token && config.headers) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
}

export function handleResponse(response: AxiosResponse) {
  return response;
}

const isSessionExpired = (error: AxiosError<ApiError>): boolean => {
  if (error.response) {
    return error.response.status === 401;
  }

  return false;
};

export function handleError(error: AxiosError<ApiError>) {
  if (isSessionExpired(error)) {
    state.global.resetSessionToken();
    window.location.href = `/${RoutesNames.login}`;
  }

  return Promise.reject(error.response);
}
