import utils from "@/services/utils/devices.utils";
import getBaseURL from "@/services/utils/base-url.utils";
import endpoints from "@/services/config/service.endpoints";
import { emsCloud as http } from "@/services/config/http.config";

import type { DeviceMetadata } from "@/types/device/device.metadata.model";
import type { DeviceTelemetry } from "@/types/device/device.telemetry.model";
import type { DeviceList, DeviceListItem } from "@/types/device/device.model";

import type {
  DevicePayload,
  DevicesListPayload,
} from "@/types/device/device.api";

const { ems } = endpoints;
const baseURL = getBaseURL(ems.main);

export default {
  async getDevice(payload: DevicePayload): Promise<DeviceListItem> {
    const { localControllerId, deviceId } = payload;

    const { data } = await http.get<DeviceListItem>(
      `${baseURL}/${localControllerId}/${ems.devices}/${deviceId}`
    );

    return data;
  },

  async getDeviceMetadata(payload: DevicePayload): Promise<DeviceMetadata> {
    const { localControllerId } = payload;

    const { data } = await http.get<DeviceMetadata>(
      `${baseURL}/${localControllerId}/${ems.devices}/${ems.metadata}`
    );

    return data;
  },

  async getDeviceTelemetry(payload: DevicePayload): Promise<DeviceTelemetry> {
    const { localControllerId, deviceId } = payload;

    const { data } = await http.get<DeviceTelemetry>(
      `${baseURL}/${localControllerId}/${ems.devices}/${deviceId}/${ems.telemetry}`
    );

    return data;
  },

  async getDevicesList(payload: DevicesListPayload): Promise<DeviceList> {
    const { filters, localControllerId } = payload;

    const params = utils.getDevicesParams(filters);
    const { data } = await http.get<DeviceList>(
      `${baseURL}/${localControllerId}/${ems.devices}?${params}`
    );

    return data;
  },
};
