import type { AxiosResponse } from "axios";

import constants from "@/constants";
import utils from "@/services/utils/bookings.utils";
import getBaseURL from "@/services/utils/base-url.utils";
import endpoints from "@/services/config/service.endpoints";
import { vehicleSharing as http } from "@/services/config/http.config";

import type { BookingFilters } from "@/types/booking/booking.state";
import type { Booking, Bookings } from "@/types/booking/booking.model";
import type { PostBooking, UpdateBooking } from "@/types/booking/booking.api";

const { bookings } = endpoints;
const baseURL = getBaseURL(bookings);

export default {
  async getBooking(id: string): Promise<Booking> {
    const { data } = await http.get<Booking>(`${baseURL}/${id}`);
    return data;
  },

  async getBookings(filters: BookingFilters): Promise<Bookings> {
    const params = utils.getBookingsParams(filters);
    const { data } = await http.get<Bookings>(`${baseURL}?${params}`);
    return data;
  },

  async createBooking(booking: PostBooking): Promise<AxiosResponse> {
    const response = await http.post<AxiosResponse>(baseURL, booking);
    return response;
  },

  updateBooking(id: string, booking: UpdateBooking): Promise<void> {
    const payload = utils.bookingUpdatedCreator(booking);
    return http.patch(`${baseURL}/${id}`, payload);
  },

  cancelBooking(id: string): Promise<void> {
    const payload = {
      status: constants.bookings.STATUS.cancelled.value,
    };
    return http.patch(`${baseURL}/${id}`, payload);
  },
};
