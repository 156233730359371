import type { WbLabel } from "@/types/wallbox.toolkit-ui";
import type { ChargerStatus } from "@/types/charger.model";

const STATUS: Record<ChargerStatus, WbLabel<ChargerStatus>> = {
  charging: {
    value: "charging",
    translation: "vehicles.status.charging",
    color: "info",
  },
  charged: {
    value: "charged",
    translation: "vehicles.status.charged",
    color: "success",
  },
  discharging: {
    value: "discharging",
    translation: "vehicles.status.discharging",
    color: "warning",
  },
  unknown: {
    value: "unknown",
    translation: "vehicles.status.unknown",
    color: "grey",
  },
} as const;

export default {
  STATUS,
};
