import type { Filter } from "@/types/filter.api";
import type { Pagination } from "@/types/pagination.api";

export interface VehiclesFilters extends Filter {
  pagination: Pagination;
}

export enum VehiclesStateItem {
  filters = "filters",
}

export interface VehiclesState {
  [VehiclesStateItem.filters]: VehiclesFilters;
}
