import { createRouter, createWebHistory } from "vue-router";

import hasAuth from "@/engine/router/guards/hasAuth";
import RoutesNames from "@/constants/routes.consts";

import LoginPage from "@/pages/Login/LoginPage.vue";

import FleetRoutes from "@/engine/router/fleet.routes";
import DevicesRoutes from "@/engine/router/devices.routes";
import SettingsRoutes from "@/engine/router/settings.routes";
import BookingsRoutes from "@/engine/router/bookings.routes";
import DashboardRoutes from "@/engine/router/dashboard.routes";

export default function () {
  const router = createRouter({
    history: createWebHistory(),
    routes: [
      {
        path: RoutesNames.home,
        name: RoutesNames.home,
        redirect: { name: `${RoutesNames.devices}-${RoutesNames.list}` },
      },
      {
        path: `/${RoutesNames.login}`,
        name: RoutesNames.login,
        component: LoginPage,
      },
      {
        path: RoutesNames.home,
        redirect: `/${RoutesNames.devices}`,
        component: () => import("@/layout/LayoutMain.vue"),
        meta: { requiresAuth: true },
        children: [
          ...DashboardRoutes,
          ...BookingsRoutes,
          ...FleetRoutes,
          ...DevicesRoutes,
          ...SettingsRoutes,
          {
            path: `/${RoutesNames.notFound}`,
            name: RoutesNames.notFound,
            component: () => import("@/pages/Commons/NotFoundPage.vue"),
          },
          { path: "/:pathMatch(.*)*", redirect: RoutesNames.notFound },
        ],
      },
    ],
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return { ...savedPosition, behavior: "smooth" };
      } else {
        return { x: 0, y: 0, behavior: "smooth" };
      }
    },
  });

  router.beforeEach(hasAuth);

  return router;
}
