<template>
  <layout-auth>
    <template #title>
      <p class="is-size-500">
        <span class="is-font-weight-700">
          {{ t("action.log-in") }}
        </span>
        <span>&nbsp;</span>
        <span>
          {{ t("action.log-in.explain") }}
        </span>
      </p>
    </template>
    <div class="login-page">
      <wb-form class="login-page__form">
        <wb-input
          v-model="data.credentials.user"
          data-test-id="login-page__input-user"
          :label="t('user.user')"
        />
        <wb-input
          v-model="data.credentials.password"
          type="password"
          data-test-id="login-page__input-password"
          :label="t('user.password')"
        />
        <wb-button
          size="large"
          data-test-id="login-page__btn-login"
          :loading="data.isLoading"
          :label="t('action.log-in')"
          :disabled="methods.isDisabled()"
          @click="methods.initApp"
        />
      </wb-form>
    </div>
  </layout-auth>
</template>

<script setup lang="ts">
import { reactive } from "vue";
import { useRouter } from "vue-router";
import { useNotify } from "@wallbox/toolkit-ui";

import state from "@/state";
import services from "@/services";
import useI18n from "@/engine/lang/useI18n";
import RoutesNames from "@/constants/routes.consts";

import type { Credentials } from "@/types/auth/auth.api";
import type { WbSelectOption } from "@/types/wallbox.toolkit-ui";
import type { LocalControllersLocation } from "@/types/local-controller/local-controller.model";

import LayoutAuth from "@/layout/LayoutAuth.vue";

interface Data {
  isLoading: boolean;
  credentials: Credentials;
}

const { t } = useI18n();
const router = useRouter();
const notify = useNotify();

const data: Data = reactive({
  isLoading: false,
  credentials: {
    password: "",
    user: "",
  },
});

const methods = {
  isDisabled() {
    return data.credentials.user === "" || data.credentials.password === "";
  },

  mapServiceDataToStateData(locations: LocalControllersLocation[]) {
    return locations.map((location) => ({
      value: location.id,
      label: location.location,
    }));
  },

  async login() {
    try {
      const { token } = await services.auth.loginEMS(data.credentials);

      state.global.setSessionToken(token);
      state.global.setSessionPayload(token);

      return true;
    } catch (error) {
      throw Error(t("error.invalid-credentials"));
    }
  },

  async getLocalControllers() {
    try {
      const localControllers =
        await services.localControllers.getLocalControllers();

      const options: WbSelectOption[] = methods.mapServiceDataToStateData(
        localControllers.local_controllers
      );

      state.global.setLocalControllers({
        locations: options,
        activeLocation: options[0],
      });

      return true;
    } catch (error) {
      throw Error(t("error.local-controllers"));
    }
  },

  async initApp() {
    methods.initLocations();
  },

  async initLocations() {
    data.isLoading = true;

    try {
      await methods.login();
      await methods.getLocalControllers();

      router.push(RoutesNames.devices);
    } catch (error) {
      data.isLoading = false;
      notify.error((error as Error).message);
    }
  },

  async initAppWithoutLocations() {
    data.isLoading = true;

    try {
      await methods.login();

      router.push(RoutesNames.devices);
    } catch (error) {
      data.isLoading = false;
      notify.error((error as Error).message);
    }
  },
};
</script>
