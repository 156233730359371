export enum DeviceAddr {
  IP = "IP",
  Modbus = "Modbus",
}

export enum DevicePriority {
  Highest = "HIGHEST",
  High = "HIGH",
  Medium = "MEDIUM",
  Low = "LOW",
  Lowest = "LOWEST",
}

export enum DeviceType {
  Frame = "frame",
  Meter = "meter",
  Charger = "charger",
  Battery = "battery",
  Photovoltaic = "pv",
  PVInverter = "pvinverter",
  Unknown = "unknown",
}

export enum DeviceModel {
  Commander = "Commander",
  Copper = "Copper",
  Pulsar = "Pulsar",
  PulsarPlus = "PulsarPlus",
  Quasar = "Quasar",
  Supernova = "Supernova",
  Generic = "Generic",
}

export enum DeviceConnectionProtocol {
  OCPP = "ocpp-1.6",
  ModbusRTU = "modbus-rtu",
  ModbusTCP = "modbus-tcp",
}

export enum DeviceStatus {
  NoData = "NO_DATA",
  Charging = "CHARGING",
  Discharging = "DISCHARGING",
  Stopped = "STOPPED",
  WaitingEvConnection = "WAITING_FOR_EV_CONNECTION",
  WaitingForAuthorization = "WAITING_FOR_AUTHORIZATION",
  WaitingForDeviceDemand = "WAITING_FOR_DEVICE_DEMAND",
  NoConnectionWithEMS = "NO_CONNECTION_WITH_EMS",
  UnableToRead = "UNABLE_TO_READ",
  DeviceError = "DEVICE_ERROR",
  Restarting = "RESTARTING",
  Enabling = "ENABLING",
  Disabling = "DISABLING",
  UpdatingOverride = "UPDATING_OVERRIDE",
  UpdatingDevice = "UPDATING_DEVICE",
  UpdatingController = "UPDATING_CONTROLLER",
  UnableToWrite = "UNABLE_TO_WRITE",
  WaitingForDeviceToStart = "WAITING_FOR_DEVICE_TO_START",
  AuthorizedWithoutEv = "AUTHORIZED_WITHOUT_EV",
}

export enum DeviceActionType {
  Restart = "restart",
  Override = "override",
  Controller = "controller",
  Device = "device",
}

export enum DeviceActionAttribute {
  MinSoc = "min_soc",
  MaxSoc = "max_soc",
  PriorityCharge = "priority_charge",
  PriorityDischarge = "priority_discharge",
  OverrideSetpoint = "override_setpoint",
}

export enum DeviceSettingType {
  Telemetry = "telemetry",
  Device = "device",
  Controller = "controller",
}
