import auth from "@/services/auth.service";
import flags from "@/services/flags.service";
import users from "@/services/users.service";
import command from "@/services/command.service";
import account from "@/services/account.service";
import bookings from "@/services/bookings.service";
import vehicles from "@/services/vehicles.service";
import settings from "@/services/settings.service";
import devices from "@/services/devices.service";
import devicesActions from "@/services/devices.actions.service";
import localControllers from "@/services/local-controllers.service";

export default {
  auth,
  flags,
  users,
  account,
  bookings,
  vehicles,
  settings,
  command,
  localControllers,
  devices: {
    ...devices,
    ...devicesActions,
  },
};
