import type { App } from "vue";
import { Vue3Mq } from "vue3-mq";

export default function mqInit(app: App) {
  app.use(Vue3Mq, {
    breakpoints: {
      mobile: 0,
      touch: 769,
      desktop: 1024,
    },
  });
}
