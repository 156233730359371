import { reactive, computed } from "vue";
import { objects } from "@wallbox/toolkit-ui";

import dates from "@/utils/dates";
import constants from "@/constants";
import { BookingStateItem } from "@/types/booking/booking.state";
import type {
  BookingState,
  BookingFilters,
} from "@/types/booking/booking.state";

const startDate = new Date();
const endDate = dates.addDays(new Date(), 7);

const filters: BookingFilters = {
  drivers: [],
  vehicles: [],
  status: [],
  dates: [startDate, endDate],
  pagination: {
    offset: 0,
    limit: constants.global.PAGINATION.LIMIT,
  },
};

const initialState: BookingState = {
  filters,
};

const state = reactive(initialState);

const getters = {
  getFilters: computed((): BookingFilters => state.filters),
};

const setters = {
  setFilters(filters: BookingFilters): void {
    objects.setProperty(state, BookingStateItem.filters, filters);
  },
};

export default reactive({
  ...getters,
  ...setters,
});
