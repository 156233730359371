import {
  DeviceAddr,
  DeviceType,
  DeviceModel,
  DeviceStatus,
  DevicePriority,
  DeviceConnectionProtocol,
} from "@/types/device/device.enum";
import type { DeviceProp, DevicePropLabel } from "@/types/device/device.ui";

const PHASE_LEVEL_MAX = 95;
const PHASE_LEVEL_MIN = 80;

/*
 * With this polling configuration, the max time is 10 minutes
 */
const POLLING_CONFIG = {
  DELTA: 2,
  MAX_NUMBER_RETRIES: 10,
  TIMEOUT_DELAY: 1000,
};

const DeviceAddrText: DeviceProp<DeviceAddr> = {
  [DeviceAddr.IP]: "devices.addr.ip",
  [DeviceAddr.Modbus]: "devices.addr.modbus",
} as const;

const DeviceTypeText: DeviceProp<DeviceType> = {
  [DeviceType.Frame]: "devices.type.frame",
  [DeviceType.Meter]: "devices.type.meter",
  [DeviceType.Charger]: "devices.type.charger",
  [DeviceType.Battery]: "devices.type.battery",
  [DeviceType.PVInverter]: "devices.type.inverter",
  [DeviceType.Photovoltaic]: "devices.type.photovoltaic",
  [DeviceType.Unknown]: "devices.type.unknown",
} as const;

const DeviceTypeIcon: DeviceProp<DeviceType> = {
  [DeviceType.Frame]: "frame",
  [DeviceType.Meter]: "type-meter",
  [DeviceType.Charger]: "charger",
  [DeviceType.Battery]: "battery_charging",
  [DeviceType.PVInverter]: "type-inverter",
  [DeviceType.Photovoltaic]: "type-inverter",
  [DeviceType.Unknown]: "help",
} as const;

const DeviceModelText: DeviceProp<DeviceModel> = {
  [DeviceModel.Commander]: "devices.model.commander",
  [DeviceModel.Copper]: "devices.model.copper",
  [DeviceModel.Quasar]: "devices.model.quasar",
  [DeviceModel.Pulsar]: "devices.model.pulsar",
  [DeviceModel.PulsarPlus]: "devices.model.pulsar-plus",
  [DeviceModel.Supernova]: "devices.model.supernova",
  [DeviceModel.Generic]: "devices.model.generic",
} as const;

const DeviceModelIcon: DeviceProp<DeviceModel> = {
  [DeviceModel.Commander]: "product-commander",
  [DeviceModel.Copper]: "product-cooper",
  [DeviceModel.Pulsar]: "product-pulsar",
  [DeviceModel.PulsarPlus]: "product-pulsar",
  [DeviceModel.Quasar]: "charger",
  [DeviceModel.Supernova]: "charger",
  [DeviceModel.Generic]: "charger",
} as const;

const DevicePriorityText: DeviceProp<DevicePriority> = {
  [DevicePriority.Highest]: "devices.priority.highest",
  [DevicePriority.High]: "devices.priority.high",
  [DevicePriority.Medium]: "devices.priority.medium",
  [DevicePriority.Low]: "devices.priority.low",
  [DevicePriority.Lowest]: "devices.priority.lowest",
} as const;

const DeviceProtocolText: DeviceProp<DeviceConnectionProtocol> = {
  [DeviceConnectionProtocol.OCPP]: "devices.protocol.ocpp",
  [DeviceConnectionProtocol.ModbusRTU]: "devices.protocol.modbus-rtu",
  [DeviceConnectionProtocol.ModbusTCP]: "devices.protocol.modbus-tcp",
} as const;

const DeviceStatusColor: DevicePropLabel<DeviceStatus> = {
  [DeviceStatus.NoData]: {
    bg: "var(--grey-100)",
    text: "var(--black)",
  },
  [DeviceStatus.Charging]: {
    bg: "var(--primary-500)",
    text: "var(--white)",
  },
  [DeviceStatus.Discharging]: {
    bg: "var(--purple-500)",
    text: "var(--white)",
  },
  [DeviceStatus.Stopped]: {
    bg: "var(--info-500)",
    text: "var(--white)",
  },
  [DeviceStatus.WaitingEvConnection]: {
    bg: "var(--grey-500)",
    text: "var(--white)",
  },
  [DeviceStatus.WaitingForAuthorization]: {
    bg: "var(--warning-500)",
    text: "var(--black)",
  },
  [DeviceStatus.WaitingForDeviceDemand]: {
    bg: "var(--warning-500)",
    text: "var(--black)",
  },
  [DeviceStatus.NoConnectionWithEMS]: {
    bg: "var(--danger-500)",
    text: "var(--white)",
  },
  [DeviceStatus.UnableToRead]: {
    bg: "var(--danger-500)",
    text: "var(--white)",
  },
  [DeviceStatus.DeviceError]: {
    bg: "var(--danger-500)",
    text: "var(--white)",
  },
  [DeviceStatus.Restarting]: {
    bg: "var(--warning-500)",
    text: "var(--black)",
  },
  [DeviceStatus.Enabling]: {
    bg: "var(--success-500)",
    text: "var(--black)",
  },
  [DeviceStatus.Disabling]: {
    bg: "var(--danger-500)",
    text: "var(--white)",
  },
  [DeviceStatus.UpdatingOverride]: {
    bg: "var(--warning-500)",
    text: "var(--black)",
  },
  [DeviceStatus.UpdatingDevice]: {
    bg: "var(--warning-500)",
    text: "var(--black)",
  },
  [DeviceStatus.UpdatingController]: {
    bg: "var(--warning-500)",
    text: "var(--black)",
  },
  [DeviceStatus.UnableToWrite]: {
    bg: "var(--danger-500)",
    text: "var(--white)",
  },
  [DeviceStatus.WaitingForDeviceToStart]: {
    bg: "var(--warning-500)",
    text: "var(--black)",
  },
  [DeviceStatus.AuthorizedWithoutEv]: {
    bg: "var(--warning-500)",
    text: "var(--black)",
  },
} as const;

const DeviceStatusText: DeviceProp<DeviceStatus> = {
  [DeviceStatus.NoData]: "devices.status.no-data",
  [DeviceStatus.Charging]: "devices.status.charging",
  [DeviceStatus.Discharging]: "devices.status.discharging",
  [DeviceStatus.Stopped]: "devices.status.stopped",
  [DeviceStatus.WaitingEvConnection]: "devices.status.waiting-ev",
  [DeviceStatus.DeviceError]: "devices.status.device-error",
  [DeviceStatus.NoConnectionWithEMS]: "devices.status.disconnected",
  [DeviceStatus.UnableToRead]: "devices.status.unable-to-read",
  [DeviceStatus.Restarting]: "devices.status.restarting",
  [DeviceStatus.Enabling]: "devices.status.enabling",
  [DeviceStatus.Disabling]: "devices.status.disabling",
  [DeviceStatus.UpdatingOverride]: "devices.status.updating",
  [DeviceStatus.UpdatingDevice]: "devices.status.updating",
  [DeviceStatus.UpdatingController]: "devices.status.updating",
  [DeviceStatus.WaitingForAuthorization]: "devices.status.waiting-auth",
  [DeviceStatus.WaitingForDeviceDemand]: "devices.status.waiting-demand",
  [DeviceStatus.UnableToWrite]: "devices.status.unable-to-write",
  [DeviceStatus.WaitingForDeviceToStart]: "devices.status.waiting-to-start",
  [DeviceStatus.AuthorizedWithoutEv]: "devices.status.auth-no-ev",
} as const;

export default {
  PHASE_LEVEL_MAX,
  PHASE_LEVEL_MIN,
  POLLING_CONFIG,
  DeviceAddrText,
  DeviceTypeText,
  DeviceTypeIcon,
  DeviceModelIcon,
  DeviceModelText,
  DeviceStatusColor,
  DeviceStatusText,
  DevicePriorityText,
  DeviceProtocolText,
};
