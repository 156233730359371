const endpoints = {
  login: "login",
  me: "me",
  users: "users",
  bookings: "bookings",
  featureFlag: "feature-flag",
  ems: {
    main: "ems",
    devices: "devices",
    tree: "tree",
    reset: "reset",
    update: "update",
    metadata: "metadata",
    telemetry: "telemetry",
    settings: "settings",
    override: "override",
    commands: "commands",
  },
  vehicles: {
    main: "vehicles",
    available: "available",
  },
} as const;

export default endpoints;
