import global from "@/constants/global.consts";
import bookings from "@/constants/bookings.consts";
import vehicles from "@/constants/vehicles.consts";
import devices from "@/constants/devices.consts";

export default {
  global,
  bookings,
  vehicles,
  devices,
};
