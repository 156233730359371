import endpoints from "@/services/config/service.endpoints";
import getBaseURL from "@/services/utils/base-url.utils";
import type { User, Users } from "@/types/user/user.model";
import type { RequestUsers } from "@/types/user/user.api";
import { vehicleSharing as http } from "@/services/config/http.config";

const { users } = endpoints;
const baseURL = getBaseURL(users);

export default {
  async getUser(id: string): Promise<User> {
    const { data } = await http.get<User>(`${baseURL}/${id}`);
    return data;
  },

  async getUsers(params?: RequestUsers): Promise<Users> {
    const { data } = await http.get<Users>(baseURL, { params });
    return data;
  },
};
