import type { RouteRecordRaw } from "vue-router";
import RoutesNames from "@/constants/routes.consts";

const bookingsRoutes: RouteRecordRaw[] = [
  {
    path: `/${RoutesNames.bookings}`,
    name: RoutesNames.bookings,
    component: () => import("@/pages/Bookings/BookingsPage.vue"),
  },
  {
    path: `/${RoutesNames.bookings}/:id`,
    name: RoutesNames.booking,
    props: true,
    component: () => import("@/pages/Bookings/BookingDetailsPage.vue"),
  },
];

export default bookingsRoutes;
