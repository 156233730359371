import getBaseURL from "@/services/utils/base-url.utils";
import endpoints from "@/services/config/service.endpoints";
import { emsCloud as http } from "@/services/config/http.config";

import type {
  DeviceActionPayload,
  DeviceActionResponse,
  DeviceActionParamsSettings,
  DeviceActionParamsOverride,
} from "@/types/device/device.api";

const { ems } = endpoints;
const baseURL = getBaseURL(ems.main);

export default {
  async restartDevice(
    payload: DeviceActionPayload
  ): Promise<DeviceActionResponse> {
    const { data, localControllerId } = payload;
    const { id } = data.device;

    const url = `${baseURL}/${localControllerId}/${ems.devices}/${id}/${ems.reset}`;

    const res = await http.patch<DeviceActionResponse>(url);

    return res.data;
  },

  async updateDevice(
    payload: DeviceActionPayload
  ): Promise<DeviceActionResponse> {
    const { data, localControllerId } = payload;
    const { id } = data.device;
    const url = `${baseURL}/${localControllerId}/${ems.devices}/${id}`;

    const { values } = data.params as DeviceActionParamsSettings;
    const res = await http.patch<DeviceActionResponse>(url, values);
    return res.data;
  },

  async overrideDevice(
    payload: DeviceActionPayload
  ): Promise<DeviceActionResponse> {
    const { data, localControllerId } = payload;
    const { id } = data.device;

    const url = `${baseURL}/${localControllerId}/${ems.devices}/${id}/${ems.override}`;

    const { override, override_setpoint } =
      data.params as DeviceActionParamsOverride;
    const config = {
      override,
      override_setpoint,
    };

    const res = await http.patch<DeviceActionResponse>(url, config);

    return res.data;
  },
};
