import { reactive, computed } from "vue";
import { objects } from "@wallbox/toolkit-ui";
import {
  type User,
  type UserState,
  UserStateItem,
} from "@/types/user/user.model";

const initialState: UserState = {
  user: null,
};

const state = reactive<UserState>(initialState);

const getters = {
  getUserData: computed((): User | null => state.user),
};

const setters = {
  setUserState(user: User): void {
    objects.setProperty(state, UserStateItem.user, user);
  },
  resetUserState(): void {
    objects.setProperty(state, UserStateItem.user, null);
  },
};

export default reactive({
  ...getters,
  ...setters,
});
