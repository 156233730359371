import type { RouteRecordRaw } from "vue-router";
import RoutesNames from "@/constants/routes.consts";

const dashboardRoutes: RouteRecordRaw[] = [
  {
    path: `/${RoutesNames.dashboard}`,
    name: RoutesNames.dashboard,
    component: () => import("@/pages/Dashboard/DashboardPage.vue"),
  },
];

export default dashboardRoutes;
