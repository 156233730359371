import type { App } from "vue";
import i18n from "@/engine/lang";
import initRouter from "@/engine/router";
import initApiMock from "@/engine/apiMock";
import initVendors from "@/engine/vendors";
import initMetrics from "@/engine/metrics";
import initFeatureFlags from "@/engine/featureFlags";

export default async function initApp(app: App) {
  await initApiMock();
  await initFeatureFlags();

  initMetrics();
  initVendors(app);

  return {
    i18n,
    router: initRouter(),
  };
}
