import {
  getParams,
  getParamsDates,
  getParamsPagination,
} from "@/services/utils/commons.utils";

import type { UpdateBooking } from "@/types/booking/booking.api";
import type { BookingFilters } from "@/types/booking/booking.state";

function getBookingsParams(filters: BookingFilters): string {
  const dates = getParamsDates(filters.dates);
  const pagination = getParamsPagination(filters.pagination);
  const drivers = getParams(filters.drivers, "driver_id");
  const status = getParams(filters.status, "booking_status");
  const vehicles = getParams(filters.vehicles, "vehicle_id");

  return `${dates}&${pagination}${drivers}${status}${vehicles}`;
}

function bookingUpdatedCreator(booking: UpdateBooking): UpdateBooking {
  if (booking.from_date && booking.to_date) {
    return {
      ...booking,
      from_date: new Date(booking.from_date).toISOString(),
      to_date: new Date(booking.to_date).toISOString(),
    };
  }

  return booking;
}

export default {
  getBookingsParams,
  bookingUpdatedCreator,
};
