import type { RouteRecordRaw } from "vue-router";
import RoutesNames from "@/constants/routes.consts";

const settingsRoutes: RouteRecordRaw[] = [
  {
    path: `/${RoutesNames.settings}`,
    name: RoutesNames.settings,
    component: () => import("@/pages/Settings/SettingsPage.vue"),
  },
];

export default settingsRoutes;
