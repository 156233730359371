import envList from "./domains.json";
import type { Env, Domain } from "./types.env";
import isApiMockActive from "@/utils/isApiMockActive";

const dynamicDomain = /(pages.dev)$/;
const { hostname } = document.location;

const envs: { [key in Domain]: Env } = envList;
let selectedEnv = envs.dev;
const domains: string[] = Object.keys(envs);

export default function getCurrentEnv(): Env {
  if (isApiMockActive) {
    return selectedEnv;
  }

  domains.forEach((domain: string) => {
    const existsDomain =
      dynamicDomain.test(hostname) ||
      envs[domain as Domain].web?.includes(hostname);

    if (existsDomain) {
      selectedEnv = envs[domain as Domain];
    }
  });

  return selectedEnv;
}
