import utils from "@/services/utils/vehicles.utils";
import getBaseURL from "@/services/utils/base-url.utils";
import endpoints from "@/services/config/service.endpoints";
import { vehicleSharing as http } from "@/services/config/http.config";

import type { VehiclesFilters } from "@/types/vehicle/vehicle.state";
import type { Vehicle, Vehicles } from "@/types/vehicle/vehicle.model";
import type { VehiclesFiltersAvailable } from "@/types/vehicle/vehicle.api";

const { vehicles } = endpoints;
const baseURL = getBaseURL(vehicles.main);

export default {
  async getVehicle(id: string): Promise<Vehicle> {
    const { data } = await http.get<Vehicle>(`${baseURL}/${id}`);
    return data;
  },

  async getAllVehicles(): Promise<Vehicles> {
    const { data } = await http.get<Vehicles>(baseURL);
    return data;
  },

  async getVehicles(filters: VehiclesFilters): Promise<Vehicles> {
    const params = utils.getVehiclesParams(filters);
    const { data } = await http.get<Vehicles>(`${baseURL}?${params}`);
    return data;
  },

  async getAvailableVehicles(
    params: VehiclesFiltersAvailable
  ): Promise<Vehicles> {
    const { data } = await http.get<Vehicles>(
      `${baseURL}/${vehicles.available}`,
      { params }
    );
    return data;
  },
};
