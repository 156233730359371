import user from "@/state/user.state";
import global from "@/state/global.state";
import account from "@/state/account.state";
import bookings from "@/state/booking.state";
import vehicles from "@/state/vehicle.state";
import devices from "@/state/devices.state";

export default {
  user,
  global,
  account,
  bookings,
  vehicles,
  devices,
};
