import type { Filter } from "@/types/filter.api";
import type { DeviceListItem } from "./device.model";
import type { Pagination } from "@/types/pagination.api";

export interface DeviceFilters extends Filter {
  type: string[];
  status: string[];
  protocol: string[];
  pagination: Pagination;
}

export enum DeviceStateItem {
  filters = "filters",
  devices = "devices",
}

export interface DeviceState {
  [DeviceStateItem.filters]: DeviceFilters;
  [DeviceStateItem.devices]: DeviceListItem[];
}
