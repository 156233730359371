import { reactive, computed } from "vue";
import { objects } from "@wallbox/toolkit-ui";
import {
  AccountStateItem,
  type Account,
  type AccountState,
} from "@/types/account/account.model";

const WB_SIRIUS_ACCOUNT = "wb-sirius-account";

const localStorageData = localStorage.getItem(WB_SIRIUS_ACCOUNT);
const storedState = localStorageData ? JSON.parse(localStorageData) : null;

const initialState: AccountState = {
  account: null,
};

const state = reactive<AccountState>(storedState || initialState);

const updateStoredState = (data: AccountState) =>
  localStorage.setItem(WB_SIRIUS_ACCOUNT, JSON.stringify(data));

const getters = {
  getAccountData: computed((): Account | null => state.account),
  getAccountName: computed((): string | "" =>
    state.account ? state.account.name : ""
  ),
  getAccountRole: computed((): string | "" =>
    state.account ? state.account.role : ""
  ),
  getInitials: computed((): string | "" => {
    if (state.account) {
      const [firstName, lastName] = state.account.name.split(" ");

      if (firstName && lastName) {
        return `${firstName.charAt(0)}${lastName.charAt(0)}`;
      }

      return firstName.charAt(0);
    }

    return "";
  }),
  getAccountEmail: computed((): string | "" =>
    state.account ? state.account.email : ""
  ),
};

const setters = {
  setAccountState(account: Account): void {
    objects.setProperty(state, AccountStateItem.account, account);
    updateStoredState(state);
  },
  resetAccountState(): void {
    objects.setProperty(state, AccountStateItem.account, null);
    updateStoredState(state);
  },
};

if (!storedState) {
  updateStoredState(state);
}

export default reactive({
  ...getters,
  ...setters,
});
