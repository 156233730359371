import type { Pagination } from "@/types/pagination.api";

function reduceParam(prev: string, next: string, name: string): string {
  return `${prev}&${name}=${next}`;
}

export function getParams(data: string[], name: string): string {
  return data.reduce((prev, next) => reduceParam(prev, next, name), "");
}

export function getParamsPagination(pagination: Pagination): string {
  const { limit, offset } = pagination;
  return `offset=${offset}&limit=${limit}`;
}

export function getParamsDates(dates: Date[]) {
  const fromDate = dates[0].toISOString();
  const toDate = dates[1].toISOString();
  return `from_date=${fromDate}&to_date=${toDate}`;
}
